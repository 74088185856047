<template>
    <div class="form-wrap">
        <div class="operate-btns">
            <div class="btn buyup" @click="openForm(1)">{{$t('SecondContract.BuyUp')}}</div>
            <div class="btn buydown" @click="openForm(-1)">{{$t('SecondContract.BuyDown')}}</div>
        </div>
        <div v-if="showModal" class="mask" @click.stop="showModal = false">
            <div id='formWrap' class="box animate__animated animate__fadeInUp" @click.stop="showModal = true">
                <div class="title">{{$t('SecondContract.Buy')}} {{curCoin.name}}</div>
                <div class="opening-options">
                    <div class="opening-quantity">
                        <div class="label">{{$t('SecondContract.SelectMultiple')}}</div>
                        <div class="select-box">
                            <div class="list">
                                <div v-for="(item,index) in openList" 
                                :key="index"
                                :class="['item', curIndex1 == index ? 'active' : '']"
                                @click="changeCurIndex1(index)">
                                    {{item}}
                                </div>
                            </div>
                            <el-input class="quantity-input" v-model="num" type="number" :placeholder="`${$t('SecondContract.OpeningQuantity')} (USDT)`"></el-input>
                        </div>
                    </div>
                    <div class="opening-time">
                        <div class="label">{{$t('SecondContract.OpeningTime')}}</div>
                        <div class="list">
                            <div v-for="(item,index) in timeList" 
                            :key="index"
                            :class="['item', curIndex2 == index ? 'active' : '']"
                            @click="changeCurIndex2(index)">
                                {{item.minute}}{{$t('SecondContract.minute')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-list">
                    <div class="info-item">
                        <div class="label">{{$t('SecondContract.Balance')}}</div>
                        <div class="value">{{balance}}USDT</div>
                    </div>
                    <div class="info-item">
                        <div class="label">{{$t('SecondContract.Transfer')}}</div>
                        <div class="value">{{actualAmount}}USDT</div>
                    </div>
                    <div class="info-item">
                        <div class="label">{{$t('SecondContract.HandlingFee')}}</div>
                        <div class="value">{{curMinute.service_fee_rate}}%</div>
                    </div>
                    <div class="info-item">
                        <div class="label">{{$t('SecondContract.Profitability')}}</div>
                        <div class="value">{{curMinute.profit_rate}}%</div>
                    </div>
                </div>
                <div v-if="buyType == 1" class="buyup buy-btn" @click="buy(1)">{{$t('SecondContract.BuyUp')}}</div>
                <div v-else class="buydown buy-btn" @click="buy(-1)">{{$t('SecondContract.BuyDown')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: false,
                buyType: 1,
                openList: [],
                curIndex1: 0,
                timeList: [],
                curIndex2: 0,
                num: '',
                curMinute: { 
                    minute: 0,
                    profit_rate: 0,
                    service_fee_rate: 0
                },
                balance: 0
            }
        },
        created() {
            this.getBalance()
            this.getSetting()
        },
        computed: {
            curCoin() {
                return this.$store.state.curCoin
            },
            
            actualAmount() {
                let num = this.num ? parseFloat(this.num) : 0
                let service_fee = this.curMinute.service_fee_rate ? parseFloat(this.curMinute.service_fee_rate) / 100 : 0
                return (num * this.openList[this.curIndex1] * (1 - service_fee)).toFixed(2)
            }
        },
        methods: {
            getBalance() {
                this.$api.credit().then(res => {
                    res.data.forEach(item => {
                        if(item.type == 'USDT') {
                            this.balance = parseFloat(item.num)
                        }
                    })
                })
            },
            
            getSetting() {
                this.$api.setting({
                    slug: 'second_conf,second_moneys'
                },300000).then(res => {
                    this.openList = res.data.second_moneys ? res.data.second_moneys.split(',') : []
                    // this.num = this.openList[this.curIndex1] || ''
                    this.timeList = res.data.second_conf ? JSON.parse(res.data.second_conf) : [],
                    this.curMinute = this.timeList[this.curIndex2] || { minute: 0, profit_rate: 0, service_fee_rate: 0}
                })
            },
            
            openForm(type) {
                this.buyType = type
                this.showModal = true
            },
            
            changeCurIndex1(index) {
                this.curIndex1 = index
                // this.num = this.openList[this.curIndex1]
            },
            
            changeCurIndex2(index) {
                this.curIndex2 = index
                this.curMinute = this.timeList[this.curIndex2]
            },
            
            buy(direction) {
                this.$confirm(this.$t('Trade.BuyTips'), '', {
                    customClass: "prompt-box-h5",
                    confirmButtonText: this.$t('Common.Confirm'),
                    cancelButtonText: this.$t('Common.Cancel'),
                    confirmButtonClass: 'prompt-confirm-btn',
                    cancelButtonClass: 'prompt-cancel-btn',
                    showClose: false,
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {
                    let loading = this.$loading.service({
                        target: '#formWrap',
                        text: this.$t('Common.Loading')
                    })
                    this.$api.secondOrderCreate({
                        product_id: this.curCoin.id,
                        direction,
                        minute: this.curMinute.minute,
                        money: this.num,
						multiple: this.openList[this.curIndex1]
                    }).then(res => {
                        loading.close()
                        this.$message({
                            message: res.message,
                            type: 'success',
                            duration: 1500
                        })
                        this.showModal = false
                        this.getBalance()
                        this.$bus.$emit('updateSecondOrder')
                    }).catch(err => {
                        loading.close()
                    })
                }).catch(() => {
                    
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-wrap {
        position: fixed;
        bottom: 6rem;
        left: 0;
        z-index: 20;
        width: 100%;
        
        .operate-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 7rem;
            box-sizing: border-box;
            padding: 0 1.2rem;
            border-bottom: 1px solid #E9E9E9;
            background-color: #FFFFFF;
            
            .btn {
                width: 48%;
                height: 4.3rem;
                line-height: 4.3rem;
                box-sizing: border-box;
                border-radius: 2px;
                font-size: 1.3rem;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }
            
            .buyup {
                background-color: #01BD8B;
            }
            
            .buydown {
                background-color: #FB474E;
            }
        }
        
        .mask {
            position: fixed;
            bottom: 6rem;
            left: 0;
            z-index: 40;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: calc(100vh - 6rem);
            background-color: rgba(0,0,0,.4);
            
            .box {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.6rem 2.4rem;
                border-bottom: 1px solid #E9E9E9;
                background-color: #FFFFFF;
                animation-duration: 500ms;
            
                .title {
                    width: 100%;
                    margin-bottom: 2rem;
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .opening-options {
                    width: 100%;
                    margin-bottom: 3.5rem;
                    
                    .opening-quantity {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-bottom: 3rem;
                        
                        .label {
                            margin: 4px 2rem 0 0;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            white-space: nowrap;
                        }
                        
                        .select-box {
                            width: 100%;
                            
                            .list {
                                display: flex;
                                flex-wrap: wrap;
                                width: 100%;
                                margin-bottom: 1rem;
                            
                                .item {
                                    min-width: 5rem;
                                    max-width: 5rem;
                                    height: 3rem;
                                    line-height: 3rem;
                                    box-sizing: border-box;
                                    margin: 0 8px 8px 0;
                                    border: 1px solid #5466EF;
                                    border-radius: 4px;
                                    font-size: 1.2rem;
                                    color: #5466EF;
                                    text-align: center;
                                    background-color: #FFFFFF;
                                    cursor: pointer;
                                }
                            
                                .active {
                                    color: #FFFFFF !important;
                                    background-color: #5466EF !important;
                                }
                            }
                            
                            /deep/ .el-input__inner {
                                width: 85%;
                                height: 3.6rem;
                                line-height: 3.6rem;
                                padding: 0 1.2rem;
                                border: 1px solid #D3D3D3;
                                border-radius: 4px;
                                font-size: 1.3rem;
                                color: #000000;
                                background-color: #FFFFFF;
                            }
                        }
                    }
                    
                    .opening-time {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        
                        .label {
                            margin: 4px 2rem 0 0;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            white-space: nowrap;
                        }
                        
                        .list {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                        
                            .item {
                                min-width: 6rem;
                                max-width: 6rem;
                                height: 3rem;
                                line-height: 3rem;
                                box-sizing: border-box;
                                margin: 0 8px 8px 0;
                                border: 1px solid #5466EF;
                                border-radius: 4px;
                                font-size: 1.2rem;
                                color: #5466EF;
                                text-align: center;
                                background-color: #FFFFFF;
                                cursor: pointer;
                            }
                        
                            .active {
                                color: #FFFFFF !important;
                                background-color: #5466EF !important;
                            }
                        }
                    }
                }
                
                .info-list {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 3.5rem;
                    
                    .info-item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 0.6rem;
                        cursor: default;
                        
                        .label {
                            max-width: 100%;
                            margin-bottom: 1rem;
                            font-size: 1.2rem;
                            color: #000000;
                            text-align: center;
                            word-wrap: break-word;
                        }
                        
                        .value {
                            max-width: 100%;
                            font-size: 1.3rem;
                            color: #000000;
                            text-align: center;
                            word-break: break-all;
                        }
                    }
                }
                
                .buy-btn {
                    width: 100%;
                    height: 4.5rem;
                    line-height: 4.5rem;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-size: 1.3rem;
                    color: #FFFFFF;
                    text-align: center;
                    cursor: pointer;
                }
                
                .buyup {
                    background-color: #01BD8B;
                }
                
                .buydown {
                    background-color: #FB474E;
                }
            }
        }
    }
</style>